import React from "react"
import { Router } from "@reach/router"
import PrivateRoute from "../../components/PrivateRoute"
import UserSettings from "../../components/userSettings"
import AppDashboard from "../../components/apps/appDashboard"
import createApp from "../../components/apps/createApp"
import Layout from "../../components/Layout"
import appDetail from "../../components/apps/appDetail"
import Invite from "../../components/Invite"

const IndexPage = () => {
  return (
    <Layout>
      <Router basepath="/user">
        <PrivateRoute path="/userSettings" component={UserSettings} />
        <PrivateRoute path="/apps/" component={AppDashboard} />
        <PrivateRoute path="/invitations" component={Invite} />
        <PrivateRoute path="/apps/createApp" component={createApp} />
        <PrivateRoute path="/apps/appDetail" component={appDetail} />
      </Router>
    </Layout>
  )
}

export default IndexPage