import React from "react"; 
const styles = require("../styles/appDetail.module.css");
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";

const Teams = ({data , handleAddDev, handleDelDev , role = "" }) => {
    return (
        <div className = {styles.cls_appDetTestersWrapper}>
            <div className = {styles.cls_overviewPart2}>
                <div className = {styles.cls_overViewAPIKeyHead}>
                    <div className = {styles.cls_overviewTitle}>Users</div>
                    {
                        role === "admin" && 
                        <div className = {styles.cls_overviewBtnWrap}>
                            <div className = {styles.cls_overviewAddAPIBtn} onClick = {() => handleAddDev()}>
                                Add New User
                            </div>
                        </div>
                    }
                </div>
                {
                    data && data.length == 0 &&
                    <div className = {styles.noApiKeysText}> No Users. Add one </div>
                }
                {
                    data.length > 0 &&
                    <TableContainer component={Paper} className = {styles.cls_apiTableCont}>
                        <div className={styles.cls_apiTableWrapper}>
                            <Table  aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                <TableCell>Email</TableCell>
                                <TableCell>Name</TableCell>
                                <TableCell align="left">Role</TableCell>
                                <TableCell align="left">Apps</TableCell>
                                <TableCell align="right"></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data.map((row, idx) => (
                                <TableRow key={idx}>
                                    <TableCell component="th" scope="row">
                                    {row.email}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                    {row.user_name}
                                    </TableCell>
                                    <TableCell align="left">{row.role}</TableCell>
                                    <TableCell align="left">All</TableCell>
                                    <TableCell align="right">
                                        {
                                            row.role != "admin" && 
                                            <div className = {styles.cls_overviewBtnWrap} onClick = {() => handleDelDev(row.developer_id)}>
                                                <div className = {styles.cls_overViewDeleteBtn}>Delete</div>
                                            </div>
                                        }
                                    </TableCell>
                                </TableRow>
                                ))}
                            </TableBody>
                            </Table>
                        </div>
                    </TableContainer>
                }
            </div>
        </div>
    )
}

export default Teams;