// extracted by mini-css-extract-plugin
export const cls_appDetail = "appDetail-module--cls_appDetail--3WOPN";
export const cls_appDetTabs = "appDetail-module--cls_appDetTabs--1PATG";
export const cls_appDetTabPanel = "appDetail-module--cls_appDetTabPanel--3ZW9n";
export const appDetailName = "appDetail-module--appDetailName--2_KpM";
export const cls_AppNameWrap = "appDetail-module--cls_AppNameWrap--rLyWX";
export const cls_AppName = "appDetail-module--cls_AppName--1j7Kd";
export const cls_overviewPart1 = "appDetail-module--cls_overviewPart1--1_ls1";
export const cls_overviewPart = "appDetail-module--cls_overviewPart--QWb8t";
export const cls_overviewTitleWrap = "appDetail-module--cls_overviewTitleWrap--XFiq3";
export const cls_overviewTitle = "appDetail-module--cls_overviewTitle--1nnXg";
export const cls_overviewTitleSpan = "appDetail-module--cls_overviewTitleSpan--28pZM";
export const cls_overviewAppStatusWrap = "appDetail-module--cls_overviewAppStatusWrap--2Uh_x";
export const cls_overViewAppDev = "appDetail-module--cls_overViewAppDev--2xomN";
export const cls_overviewDescWrap = "appDetail-module--cls_overviewDescWrap--c_QsR";
export const cls_overviewDesc = "appDetail-module--cls_overviewDesc--2pLY7";
export const cls_overviewAppEditBtn = "appDetail-module--cls_overviewAppEditBtn--1gjjg";
export const cls_overviewEdit = "appDetail-module--cls_overviewEdit--1i8Gs";
export const cls_overViewAPIKeyHead = "appDetail-module--cls_overViewAPIKeyHead--3QJjN";
export const cls_overviewBtnWrap = "appDetail-module--cls_overviewBtnWrap--UWCbG";
export const cls_overviewAddAPIBtn = "appDetail-module--cls_overviewAddAPIBtn--3l-AR";
export const noApiKeysText = "appDetail-module--noApiKeysText--_stZr";
export const cls_overviewDeleteApp = "appDetail-module--cls_overviewDeleteApp--1TaR8";
export const cls_overViewWarningText = "appDetail-module--cls_overViewWarningText--2UmeF";
export const cls_overViewDeleteBtn = "appDetail-module--cls_overViewDeleteBtn--1lBC7";
export const apiKeyText = "appDetail-module--apiKeyText--1c8ce";
export const cls_apiBtnWrapper = "appDetail-module--cls_apiBtnWrapper--1EfIH";
export const cls_appCreateBtn = "appDetail-module--cls_appCreateBtn--2pFWb";
export const cls_appCancelBtn = "appDetail-module--cls_appCancelBtn--1Yem0";
export const apiKeyBoldText = "appDetail-module--apiKeyBoldText--WLyHO";
export const apiKeyValWrapper = "appDetail-module--apiKeyValWrapper--2YuWF";
export const apiKeyVal = "appDetail-module--apiKeyVal--i9DLh";
export const cls_apiTableCont = "appDetail-module--cls_apiTableCont--ydvZT";
export const cls_apiTableWrapper = "appDetail-module--cls_apiTableWrapper--3uODd";
export const revokeAccessBtn = "appDetail-module--revokeAccessBtn--1rFcN";
export const apiKeyTextError = "appDetail-module--apiKeyTextError--7SAwk";
export const cls_appDetinputBox = "appDetail-module--cls_appDetinputBox--2j_fH";