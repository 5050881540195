import { Box, Button, FormControl, FormHelperText, MenuItem, OutlinedInput, Select, } from "@material-ui/core";
import { Formik } from "formik";
import React from "react";
const styles = require("../styles/userSettings.module.css"); 

const AppSettings = ({appName = "", appID = "", handleSubmit}) => {
    const validate = ( values : any) => {
        let errors = {};
        if (values.app_name === "") {
            Object.assign(errors, {["app_name"]: "Team name cannot be empty"});
        }
        return errors;
    };
    return (
        <div className = {styles.userSetPageWrapper}>
            <div className = {styles.userSetPage}>
                <div className = {styles.userSetTitle}>
                    Settings
                </div>
                <div className = {styles.userSetBody}>
                    <div className = {styles.userSetCont}>
                        <div className = {styles.userInfoWrap}>
                            <Formik
                                enableReinitialize
                                initialValues={{
                                    app_name: appName ,
                                }}
                                validate={(initialValues) => validate(initialValues)} 
                                onSubmit={ (values: any) => {
                                    handleSubmit(values.app_name);
                                }}>

                                {({
                                    errors,
                                    handleBlur,
                                    handleChange,
                                    handleSubmit,
                                    isSubmitting,
                                    touched,
                                    values,
                                }) => (
                                    <form onSubmit={handleSubmit}>
                                        <Box my={3}>
                                            <FormControl className={styles.userSetFormControl} variant="outlined">
                                                <div id="app_name" className = {styles.userSetFormLabel}>Team Name</div>
                                                <OutlinedInput
                                                    id="outlined-app_name"
                                                    value={values.app_name}
                                                    onBlur={handleBlur('app_name')}
                                                    onChange={handleChange('app_name')}
                                                    className = {styles.setFormInput}
                                                    labelWidth={0}
                                                />
                                                { 
                                                    touched.app_name && errors.app_name && 
                                                    <FormHelperText style = {{color: "#f44336"}}>Team Name is Required</FormHelperText>
                                                }
                                            </FormControl>
                                            <FormControl className={styles.userSetFormControl} variant="outlined">
                                                <div id="userID" className = {styles.userSetFormLabel}>Team ID</div>
                                                <div className = {styles.useSetFormInputUserID}>{appID}</div>
                                                <div className = {styles.userIDHelper}>Note: Team ID is unique and is not editable</div>
                                            </FormControl>
                                            {/* <FormControl 
                                                variant="standard" 
                                                fullWidth 
                                                className = {styles.cls_IndustrySelect}
                                                >
                                                <div id="appearance" className = {styles.userSetFormLabel}>Appearance </div>
                                                <Select
                                                labelId="appearance"
                                                id="appearance"
                                                value="dark"
                                                onBlur={handleBlur}>
                                                    <MenuItem value="dark">Dark</MenuItem>
                                                </Select>
                                            </FormControl> */}
                                        </Box>
                                        <Box my={2}>
                                            <Button
                                                // className = {styles.loginBtn}
                                                color="primary"
                                                disabled={isSubmitting}
                                                fullWidth
                                                size="large"
                                                type="submit"
                                                variant="contained"
                                                className = {styles.useSetSubBtn}
                                            >
                                                Submit
                                            </Button>
                                        </Box>
                                    </form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AppSettings;